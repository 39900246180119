import { useCallback, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';

import './NewsletterForm.css';

const validationSchema = object().shape({
    email: string().email().required(),
});

function NewsletterForm() {
    const { register, handleSubmit, formState: { errors, isSubmitSuccessful }, reset } = useForm({ resolver: yupResolver(validationSchema) });
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [loading, setLoading] = useState(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [error, setError] = useState('');

    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return '';
        }
        // return token
        try {
            return await executeRecaptcha('newsletterForm');
        } catch (e) {
            console.error(e);
            setError(e.message);
        }
        return '';
    }, [executeRecaptcha]);

    const submitHandler = async (data) => {
        setLoading(true);

        if (!process.env['NODE_ENV'] || process.env['NODE_ENV'] === 'development') {
            console.log(data);
            console.log('Submitting to SendGrid');
            setLoading(false);
            setIsFormSubmitted(true);
            reset();
            return;
        }

        const recaptchaToken = await handleReCaptchaVerify();
        if (recaptchaToken) {
            try {
                const formData = new FormData();
                formData.append('email', data.email);
                formData.append('g-recaptcha-response', recaptchaToken);

                const response = await fetch('/api/submit', {
                    method: 'POST',
                    body: formData
                });

                if (response.status === 200) {
                    reset();
                    setIsFormSubmitted(true);
                } else {
                    throw new Error('Error submitting to SendGrid: ' + (await response.text()));
                }

            } catch (err) {
                console.error(err);
                setError(err.message);
            }
            setLoading(false);
        } else {
            setError('ReCaptcha not available');
        }
    };

    return (
        <>
            {!isFormSubmitted
                ? (
                    <form onSubmit={handleSubmit(submitHandler)}>
                        <div className="mb-3">
                            <label htmlFor="emailInput" className="form-label text-white h6">Email address:</label>
                            <input
                                type="email"
                                className="form-control"
                                id="emailInput"
                                placeholder="name@example.com"
                                name="email"
                                required
                                {...register('email')}
                            />
                            {(errors && errors.email) && <div className="text-danger">{errors.email.message}</div>}
                        </div>
                        <div className="col-auto d-flex justify-content-center pt-4">
                            {loading
                                ? (
                                    <div className="spinner-border text-light" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                )
                                : <button type="submit" className="btn btn-primary mb-3">Stay tuned!</button>
                            }
                        </div>
                        {!!error && <div className="text-danger">{error}</div>}
                        <p className='privacy'>By submitting, you agree with our <a href="https://www.iubenda.com/privacy-policy/80373368" target="_blank" rel="noopener noreferrer">Privacy Policy</a></p>
                    </form>
                )
                : <h4 className='text-white'>#EnjoyTheRide!</h4>
            }
        </>
    )
}

export default NewsletterForm;