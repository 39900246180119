import { useCallback, useEffect, useState } from 'react';
import { Typewriter, Cursor } from 'react-simple-typewriter';

import './Question.css';

function Question({ question, questionIndex, answers, isVisible, onAnswer }) {
    const [printedQuestion, setPrintedQuestion] = useState(false);
    const [pointedAnswer, setPointedAnswer] = useState(0);
    const [selectedAnswer, setSelectedAnswer] = useState(null);
    const [isCompleted, setIsCompleted] = useState(false);
    const [isTipVisible, setIsTipVisible] = useState(false);

    const selectAnswer = useCallback((index) => {
        if (!isCompleted) {
            setSelectedAnswer(index);
            if (answers[index].correct) {
                setIsCompleted(true);
                onAnswer(questionIndex);
            } else {
                setPointedAnswer(index + 1 > answers.length - 1 ? 0 : index + 1);
                setIsTipVisible(true);
            }
        }
    }, [onAnswer, answers, questionIndex, isCompleted]);

    const handleKeyDown = useCallback((e) => {
        if (!isCompleted) {
            if (e.key === 'Enter') {
                selectAnswer(pointedAnswer);
            } else if (e.key === 'ArrowUp') {
                setPointedAnswer(prev => prev > 0 ? prev - 1 : 0);
            } else if (e.key === 'ArrowDown') {
                setPointedAnswer(prev => prev < answers.length - 1 ? prev + 1 : answers.length - 1);
            }
        }
    }, [isCompleted, setPointedAnswer, answers, selectAnswer, pointedAnswer]);

    useEffect(() => {
        if (isVisible) {
            window.addEventListener('keydown', handleKeyDown);

            return () => {
                window.removeEventListener("keydown", handleKeyDown);
            };
        }
    }, [isVisible, handleKeyDown]);

    if (!isVisible) {
        return null;
    }

    return (
        <div className='text-white text mt-4 text-wrap'>
            <Typewriter
                words={[question]}
                loop={1}
                typeSpeed={70}
                deleteSpeed={50}
                delaySpeed={1000}
                onLoopDone={() => setPrintedQuestion(true)}
            />
            {!printedQuestion
                ? <Cursor cursorStyle='_' />
                : (
                    <div>
                        {answers.map((answer, index) => (
                            <div
                                key={index}
                                className="d-flex lex-sm-row justify-content-start align-items-center ms-3 mb-1"
                                onClick={selectAnswer.bind(this, index)}
                            >
                                {(!isCompleted && selectedAnswer !== index) && pointedAnswer === index &&
                                    <i className={
                                        `bi bi-caret-right-fill answer-pointer ${pointedAnswer === index ? 'answer-color' : ''}`
                                    }></i>
                                }
                                {(selectedAnswer === index || isCompleted)
                                    ? (
                                        answer.correct
                                            ? <i className="bi bi-check-lg answer-color"></i>
                                            : <i className="bi bi-x-lg wrong-answer-color"></i>
                                    )
                                    : null
                                }
                                <label className={`form-check-label d-flex alignment ps-2 ${
                                    (selectedAnswer === index || isCompleted)
                                        ? (answer.correct ? 'answer-color' : '')
                                        : (pointedAnswer === index ? 'answer-pointer answer-color' : 'answer-pointer answer-padding')
                                    }`
                                }>
                                    {answer.text}
                                </label>
                            </div>
                        ))}
                        {
                            (!isCompleted && isTipVisible) && <span className="badge Qtip ms-3 tip-bg">Try again</span>
                        }
                    </div>
                )}
        </div>
    );
}

export default Question;
