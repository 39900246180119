let hasLoadedGA = false;

export const loadGA = (GA_MEASUREMENT_ID) => {
    if (typeof window === "undefined" || typeof document === "undefined" || !process.env['NODE_ENV'] || process.env['NODE_ENV'] === 'development') {
        return;
    }

    if (!hasLoadedGA) {
        // Global Site Tag (gtag.js) - Google Analytics
        const script = document.createElement("script");
        script.async = true;
        script.src  = `https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`;
        document.body.appendChild(script);

        window.dataLayer = window.dataLayer || [];
        window.gtag = function gtag() {
            window.dataLayer.push(arguments);
        };

        hasLoadedGA = true;
        
        window.gtag('js', new Date());
        window.gtag('config', GA_MEASUREMENT_ID, { 'anonymize_ip': true });
    }
};