import { useCallback, useState } from 'react';
import { Typewriter, useTypewriter, Cursor } from 'react-simple-typewriter'
import './Terminal.css';
import Question from './Question';
import Car from './Car';
import NewsletterForm from './NewsletterForm';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const questions = [
  {
    question: '- We are working on:',
    answers: [
      {
        text: 'Space shuttle simulation',
        correct: false,
      },
      {
        text: 'Motorsport revolution',
        correct: true,
      },
    ],
  },
  {
    question: '- Our events will be:',
    answers: [
      {
        text: 'Virtual events',
        correct: true,
      },
      {
        text: 'Real events',
        correct: false,
      },
    ],
  },
  {
    question: '- You will be competing in:',
    answers: [
      {
        text: 'Horse robots races',
        correct: false,
      },
      {
        text: 'Sim car racing',
        correct: true,
      },
    ],
  },
  {
    question: '- Your talent on our platform will make you earn:',
    answers: [
      {
        text: 'Real money',
        correct: true,
      },
      {
        text: 'The glory',
        correct: true,
      },
    ],
  }
];

function Terminal() {
  const [wrap, setWrap] = useState(false);
  const [start, setStart] = useState(false);
  const [isLastTipVisible, setIsLastTipVisible] = useState(false);
  const [tip, setTip] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [carStart, setCarStart] = useState(false);
  const [formEmail, setFormEmail] = useState(false);

  const [currentQuestion, setCurrentQuestion] = useState(0);

  let words = [
    'Welcome to SimWin',
    '#EnjoyTheRide!',
    "I'm going to ask you a few questions...",
    'Are you ready? (y/n)'
  ]

  const { text } = useTypewriter({
    words: [words[0], words[1], words[2], words[3]],
    loop: 1,
    typeSpeed: 100,
    deleteSpeed: 38,
    cursor: true,
  });

  const handleEnter = (e) => {
    let input = e.target.value;
    if (input.toLowerCase() === 'y') {
      setInputValue(input);
      setTimeout(() => {
        setWrap(true);
      }, 500);
    }
    else {
      setTip(true);
      setInputValue('');
      e.target.focus()
    }
  }

  const handleDoneStart = () => {
    setTimeout(() => {
      setStart(true);
    }, 2000);
  }

  const handleAnsweredQuestion = (questionIndex) => {
    if (questionIndex === questions.length - 1) {
      setIsLastTipVisible(true);
      setTimeout(() => {
        setCarStart(true);
      }, 2000);
    }
    setCurrentQuestion(questionIndex + 1);
  };

  const onCarAnimationEnd = useCallback(() => {
    setFormEmail(true);
  }, []);

  return (
    <>
      {!carStart ?
        <div className='m-3 p-3 terminal-cont rounded border'>
          {!wrap ? (
            <div className='text-white m-0 p-0 text'>
              <span className='d-inline-block text-wrap'>{text}
              {text !== words[3] && <Cursor cursorStyle='_' />}
              </span> 
              {text === words[3] && <input className='input px-2 py-0' type='text' placeholder="type 'y'" value={inputValue} autoFocus onChange={handleEnter} />}
              {tip && <h5 className='text my-2 tip'>Please, type 'y'</h5>}
            </div>) : (
            <div className='text-white m-0 text p-0'>
              <Typewriter
                words={["Good! Let's start!"]}
                loop={1}
                typeSpeed={70}
                deleteSpeed={50}
                delaySpeed={1000}
                onLoopDone={handleDoneStart}
              />
              {!start && <Cursor cursorStyle='_' />}
            </div>
          )
          }
          {
            start && (
              questions.map((question, index) => (
                <Question
                  key={index}
                  question={question.question}
                  questionIndex={index}
                  answers={question.answers}
                  onAnswer={handleAnsweredQuestion}
                  isVisible={index <= currentQuestion}
                />
              ))
            )
          }
          {
            isLastTipVisible && <span className="badge ms-3 Qtip tip-bg">Exactly, both are correct!</span>
          }
        </div> : (
          !formEmail &&
          <Car
            onAnimationEnd={onCarAnimationEnd}
            hasEnterAnimation
          />
        )
      }
      {
        formEmail &&
        <div className='m-3 p-3 terminal-cont form rounded border animate__animated animate__zoomInRight'>
          <Car />
          <h1 className='text-white h6 pb-4'>SimWin. Earn from your talent.</h1>
          <GoogleReCaptchaProvider
            reCaptchaKey={process.env['REACT_APP_RECAPTCHA_KEY']}
          >
            <NewsletterForm />
          </GoogleReCaptchaProvider>
        </div>
      }
    </>



  );
}

export default Terminal;
