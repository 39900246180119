import './Dashboard.css';
import Terminal from './Terminal';
import { useState, useEffect } from 'react';
import { Typewriter, Cursor } from 'react-simple-typewriter'


function Dashboard() {
    const [count, setCount] = useState(true);
    const [value, setValue] = useState(0);
    const [delay, setDelay] = useState(1500);
    const [startMPH, setStartMPH] = useState(false);
    const [startType, setStartType] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setStartType(true);
        }, 800);
    });

    useEffect(() => {
        setTimeout(() => {
            if (value < 99) {
                setTimeout(() => {
                    setValue((prev) => {
                        return prev + 1;
                    });
                }, 30);
                setDelay(30);
            } else {
                setTimeout(() => {
                    setCount(false);
                }, 1000);

            }
        }, delay);

    }, [delay, value]);

    const handleDoneStart = () => {
        setStartMPH(true);
    }


    return (
        <div className={count ? 'cont-1 d-flex flex-row justify-content-center align-items-center' : 'cont-2 d-flex flex-row justify-content-center align-items-center'} >
            {count ?
                (<div className='d-flex justify-content-center bg cont-1'>
                    <div className="table">
                        <div className="table-cell">
                            <div id="main">
                                <div id="screen">
                                    <div id="home">
                                        <div id="radial">
                                            <div id="empty"></div>
                                        </div>
                                        <h1 id="countdown">{value}</h1>
                                        <div id="mph" className={!startMPH ? 'text-white mph' : 'text-white'}>
                                            {startType &&
                                                <Typewriter
                                                    words={["Km/h"]}
                                                    loop={1}
                                                    typeSpeed={120}
                                                    delaySpeed={1000}
                                                    onLoopDone={handleDoneStart}
                                                />
                                            }
                                            {!startMPH && <Cursor cursorStyle='_' />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>) : <Terminal />
            }
            <div className="cookie-policy-container position-absolute bottom-0 start-0 w-100 text-center">
                <a href="https://www.iubenda.com/privacy-policy/80373368/cookie-policy" target="_blank" rel="noopener noreferrer">Cookie Policy</a>
            </div>
        </div>
    )
}

export default Dashboard;