import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Dashboard from './Components/Dashboard';
import './App.css';
import 'animate.css';
import {loadGA} from './libs/ga';

loadGA(process.env['REACT_APP_GA_MEASUREMENT_ID']);

function App() {

  return (
    <>
      <Dashboard />
    </>
  );
}

export default App;
